<template>
  <div
    :class="'recharge-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\''"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/member_bg.jpg')" />
      <div class="credit">
        <span class="title">{{ PAGELANG.credit }}</span>
        <span class="price">{{ agentInfo.Balance }}</span>
      </div>
    </div>

    <div class="credit-block">
      <van-form @submit="onSubmit">
        <div class="van-cell van-field">
          <div class="van-cell__title van-field__label van-field__label--top">
            {{ PAGELANG.收款账户 }}
          </div>
          <template v-for="(account, index) in useraccount" :key="index">
            <div
              class="van-cell__value van-field__value"
              :style="
                'flex: none; width: 100%; text-align: left;' +
                (index == 0 ? '' : 'margin-top:6px;')
              "
            >
              <div class="van-field__body" style="color: #333">
                {{ PAGELANG.账户号 }}: {{ account.Account }}<br />
                {{ PAGELANG.开户行 }}: {{ account.BankName }}<br />
                {{ PAGELANG.收款人 }}: {{ account.AccountName }}
              </div>
            </div>
          </template>
          <div
            class="van-cell__value van-field__value"
            style="flex: none; width: 100%; text-align: left; margin-top: 6px"
          >
            <div class="van-field__body" style="color: #f66">
              <span v-if="op == 'repair' && repair > 0"
                >{{ PAGELANG.温馨提示 }}:
                {{ SYSLANG.您上一年度平台服务费未满足减免条件
                }}{{ formatMoney(repair) }}</span
              >
              <span v-else
                >{{ PAGELANG.温馨提示 }}: {{ PAGELANG.充值限制 }}
                {{ SYSLANG.currency }}{{ chargelimit }}
                {{ PAGELANG.倍数 }}</span
              >
            </div>
          </div>
        </div>
        <van-field
          v-model="Amount"
          name="Amount"
          :formatter="formatter"
          :label="PAGELANG.rechargemoney"
          :placeholder="PAGELANG.rechargemoneyplaceholder"
          :rules="[
            {
              required: true,
              validator: checkAmount,
              message: this.PAGELANG.rulesamount,
            },
          ]"
          label-align="top"
          class="amount"
          :readonly="op == 'repair' && repair > 0"
        />
        <van-field
          name="TradeNo"
          v-model="TradeNo"
          :label="PAGELANG.tradeno"
          :placeholder="PAGELANG.tradenoplaceholder"
          :rules="[{ required: true, message: this.PAGELANG.rulestradeno }]"
          label-align="top"
        />
        <div class="van-cell van-field">
          <div class="van-cell__title van-field__label van-field__label--top">
            {{ PAGELANG.tradeproof }}
          </div>
          <van-uploader
            v-model="TradeProof"
            multiple
            :max-count="5"
            :upload-text="PAGELANG.tradeprooftext"
            :before-read="beforeRead"
            :after-read="uploadTradeProof"
            class="trade-proof"
          />
          <div
            v-if="showTradeProofMsg"
            class="van-field__error-message"
            style="width: 100%"
          >
            {{ PAGELANG.relestradeproof }}
          </div>
        </div>
        <van-button
          type="primary"
          block
          color="var(--van-card-price-color)"
          native-type="submit"
          class="btn-submit"
          >{{ PAGELANG.submit }}</van-button
        >
      </van-form>
    </div>
  </div>

  <div
    :class="'pc-my-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\''"
  >
    <MyMenu :agentInfo="agentInfo" pagename="credit" />
    <div class="main">
      <div class="credit-info">
        <div class="item">
          <span class="num price">{{ agentInfo.Balance }}</span>
          {{ PAGELANG.credit }}
        </div>
      </div>
      <el-form
        :label-width="labelWidth"
        class="recharge-form"
        :model="recharge"
        :rules="rules"
        ref="refRecharge"
      >
        <el-form-item :label="PAGELANG.收款账户">
          <div
            v-for="(account, index) in useraccount"
            :key="index"
            class="account"
          >
            <div class="c-01">
              <label>{{ PAGELANG.账户号 }}: </label>{{ account.Account }}
            </div>
            <div class="c-01">
              <label>{{ PAGELANG.开户行 }}: </label>{{ account.BankName }}
            </div>
            <div class="c-02">
              <label>{{ PAGELANG.收款人 }}: </label>{{ account.AccountName }}
            </div>
          </div>
          <div class="van-field__body" style="color: #f66">
            <span v-if="op == 'repair' && repair > 0"
              >{{ PAGELANG.温馨提示 }}:
              {{ SYSLANG.您上一年度平台服务费未满足减免条件
              }}{{ formatMoney(repair) }}</span
            >
            <span v-else
              >{{ PAGELANG.温馨提示 }}: {{ PAGELANG.充值限制 }}
              {{ SYSLANG.currency }}{{ chargelimit }} {{ PAGELANG.倍数 }}</span
            >
          </div>
        </el-form-item>

        <el-form-item :label="PAGELANG.rechargemoney" prop="Amount">
          <el-input
            name="Amount"
            v-model="recharge.Amount"
            :placeholder="PAGELANG.rechargemoneyplaceholder"
            :formatter="formatter"
            style="width: 20vw"
            :readonly="op == 'repair' && repair > 0"
          />
        </el-form-item>

        <el-form-item :label="PAGELANG.tradeno" prop="TradeNo">
          <el-input
            name="TradeNo"
            v-model="recharge.TradeNo"
            :placeholder="PAGELANG.tradenoplaceholder"
            style="width: 20vw"
          />
        </el-form-item>

        <el-form-item :label="PAGELANG.tradeproof" :required="true">
          <van-uploader
            v-model="TradeProof"
            multiple
            :max-count="3"
            :upload-text="PAGELANG.tradeprooftext"
            :before-read="beforeRead"
            :after-read="uploadTradeProof"
            preview-size="10vw"
            image-fit="contain"
            class="uploader"
          />
          <div
            v-if="showTradeProofMsg"
            class="van-field__error-message"
            style="width: 100%"
          >
            {{ PAGELANG.relestradeproof }}
          </div>
        </el-form-item>

        <el-form-item>
          <el-button
            color="#000"
            class="btn-submit"
            style="width: 10vw"
            @click="onSubmitPC"
            size="large"
            >{{ PAGELANG.submit }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { showLoadingToast, showToast, closeToast } from "vant";
import { doResizeImage, getCookie } from "../util/index.js";
import MyMenu from "../components/MyMenu.vue";

export default {
  components: {
    MyMenu,
  },
  data() {
    return {
      agentInfo: {},
      Amount: "",
      TradeNo: "",
      TradeProof: [],
      showTradeProofMsg: false,
      useraccount: [],
      chargelimit: 0,
      recharge: {},
      PAGELANG: {},
      rules: {},
      labelWidth: "120px",
      op: "",
      repair: 0,
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.recharge;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;

    if (this.$route.query.op) {
      this.op = this.$route.query.op;
    }

    if (this.LANGCODE != "zh") {
      this.labelWidth = "190px";
    }

    this.rules = {
      Amount: [{ required: true, validator: this.checkAmountPC }],
      TradeNo: [{ required: true, message: this.PAGELANG.rulestradeno }],
    };

    this.getMemberInfo();
  },
  methods: {
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .get(this.actions.credit + "&op=" + this.op)
        .then(({ data }) => {
          closeToast();
          let { code, msg, agentInfo, useraccount, chargelimit, repair } = data;
          console.log(code, msg, useraccount);
          if (code == "SUCCESS") {
            this.agentInfo = agentInfo;
            this.useraccount = useraccount;
            this.chargelimit = chargelimit;
            this.repair = repair;
            if (repair > 0) {
              this.recharge.Amount = this.Amount = this.formatMoney(repair);
            }
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          closeToast();
          console.log("views.CreditRecharge.getMemberInfo.error", error);
          this.getMemberInfo();
        });
    },
    formatMoney(money) {
      if (!money) return "0.00";
      let integer = Math.floor(money).toString();
      let thousands = integer
        .split("")
        .reverse()
        .join("")
        .replace(/(\d{3})/g, "$1.")
        .replace(/,$/, "");

      if (thousands.substr(-1, 1) == ".") {
        thousands = thousands.substring(0, thousands.length - 1);
      }

      return thousands.split("").reverse().join("");
    },
    formatter(val) {
      return val.replace(/[^\d]*/g, "").replace(/(?=(\B)(\d{3})+$)/g, ".");
    },
    beforeRead(file) {
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/gif"
      ) {
        showToast(this.PAGELANG.uploadfile);
        return false;
      }
      return true;
    },
    uploadTradeProof(file) {
      this.showTradeProofMsg = false;
      console.log(file);
    },
    checkAmount(val) {
      console.log(val);
      let curval = val.replaceAll(".", "");
      let climit = this.chargelimit.replaceAll(".", "");
      if ((curval * 1) % climit !== 0 && this.op != "repair") {
        return (
          this.PAGELANG.充值限制 +
          " " +
          this.SYSLANG.currency +
          this.chargelimit +
          " " +
          this.PAGELANG.倍数
        );
      }
    },
    checkAmountPC(rule, value, callback) {
      console.log(rule, value);
      if (!value) {
        return callback(new Error(this.PAGELANG.rulesamount));
      } else {
        let curval = value.replaceAll(".", "");
        let climit = this.chargelimit.replaceAll(".", "");
        if ((curval * 1) % climit !== 0 && this.op != "repair") {
          return callback(
            new Error(
              this.PAGELANG.充值限制 +
                " " +
                this.SYSLANG.currency +
                this.chargelimit +
                " " +
                this.PAGELANG.倍数
            )
          );
        } else {
          return callback();
        }
      }
    },
    onSubmitPC() {
      this.$refs.refRecharge.validate((valid) => {
        if (valid) {
          this.Amount = this.recharge.Amount;
          this.TradeNo = this.recharge.TradeNo;
          this.onSubmit();
        }
      });
    },
    onSubmit() {
      if (this.TradeProof.length <= 0) {
        this.showTradeProofMsg = true;
        return false;
      }

      showLoadingToast({
        message: this.SYSLANG.正在提交申请,
        forbidClick: true,
        duration: 0,
      });

      this.issubmit = true;
      this.showTradeProofMsg = false;
      let TradeProof = [];
      for (let i = 0; i < this.TradeProof.length; i++) {
        doResizeImage(this.TradeProof[i].content, 1000, 1000).then((res) => {
          this.TradeProof[i].content = res;
          TradeProof[i] = res;
          if (i == this.TradeProof.length - 1) {
            let postdata = new Object();
            postdata.Amount = this.Amount.replaceAll(".", "");
            postdata.TradeNo = this.TradeNo;
            postdata.TradeProof = TradeProof;
            postdata.Type = this.op;

            console.log(postdata);

            this.axios
              .post(this.actions.recharge, JSON.stringify(postdata))
              .then(({ data }) => {
                let { code, msg } = data;
                console.log(code, msg);
                closeToast();

                showToast({
                  message:
                    code == "NOTLOGIN"
                      ? this.SYSLANG[msg]
                      : code == "MULTIPLES"
                      ? this.PAGELANG.充值限制 +
                        " " +
                        this.SYSLANG.currency +
                        this.chargelimit +
                        " " +
                        this.PAGELANG.倍数
                      : this.PAGELANG[msg],
                  forbidClick: true,
                  onClose: () => {
                    console.log(this.op);
                    if (this.op == "repair") {
                      this.$router.push("/login");
                    } else {
                      this.$router.replace({
                        path: "/credit/rechargelogs",
                      });
                    }
                  },
                });
              })
              .catch((error) => {
                closeToast();
                console.log("views.CreditRecharge.onSubmit.error", error);
              });
          }
        });
      }
    },
  },
};
</script>

<style src="../assets/css/recharge.css" scoped></style>
<style scoped>
.recharge-page:not(.zh) .trade-proof >>> .van-uploader__upload-text {
  line-height: 14px;
  text-align: center;
}
</style>